@static-path: '.';

@main-color: #333;
@active-color: #1a7090;
@hover-color: #5e5e5e;
@border-color: #ccc;
@error-color: #ff5555;

@disabled-color: #baa;
@form-hover-background: lighten(@border-color, 15%);
@form-selected-background: #9dc2d0;

// breakpoints for popular resolutions
// are copied from bootstrap and unused yet;)
// See: http://getbootstrap.com/css/#grid-media-queries
@common-screen-tiny: 480px;
@common-screen-small: 768px;
@common-screen-medium: 992px;
@common-screen-large: 1200px;

#large-dim() {
	@base-size: 14px;

	@icon-size: 26px;
	@expanded-icon-size: 20px;
	@button-size: 32px;
	@button-grow: 4px; // additional padding button may grow

	@atom-size: 19px;

	@menu-pad: 7px;
	@menu-font-size: 14px;
	@menu-icon-size: 18px;

	@app-gap: 7px - @button-grow;
	@toolbar-gap: 6px - @button-grow;

	@toolbar-size: @button-size + 2 * @button-grow; // + border?
	@canvas-padding: @toolbar-size + @toolbar-gap + @app-gap;
}

#small-dim() {
	@base-size: 11px;

	@icon-size: 20px;
	@expanded-icon-size: 17px;
	@button-size: 24px;
	@button-grow: 4px;

	@atom-size: 16px;

	@menu-pad: 5px;
	@menu-font-size: 12px;
	@menu-icon-size: 16px;

	@app-gap: 7px - @button-grow;
	@toolbar-gap: 6px - @button-grow;

	@toolbar-size: @button-size + 2 * @button-grow;
	@canvas-padding: @toolbar-size + @toolbar-gap + @app-gap;
}

// Periodic table coloring

@atom-periodic-diatomic: #e7ff8f;
@atom-periodic-noble-gas: #c0ffff;
@atom-periodic-alkali-metal: #ff6666;
@atom-periodic-alkaline-earth-metal: #ffdead;
@atom-periodic-metalloid: #cccc99;
@atom-periodic-polyatomic: #a1ffc3;
@atom-periodic-post-transition-metal: #cccccc;
@atom-periodic-transition-metal: #ffc0c0;
@atom-periodic-lanthanide: #ffbfff;
@atom-periodic-actinide: #ff99cc;
@atom-periodic-unknown-properties: #e8e8e8;
